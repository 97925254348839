@import "~react-image-gallery/styles/css/image-gallery.css";

._loading_overlay_overlay {
    z-index: 11111;
    height: 100vh;
    .css-42igfv {
        position: fixed !important;
        left: 50%;
        top: 45%;
        @media (max-width: 767px) {
            left: 40%;
            top: 50%;
        }
    }
}

.main_container,
.search_components {
    display: flex;
    flex-direction: column;
    align-items: center;
    // gap: 30px;
    // padding: 40px;
    .search_components {
        // opacity: 1;
        width: 100% !important;
        .scanner {
            // height: 300px;
            width: 210px;
            margin-top: 30px;
            section {
                > div {
                    border: 83px solid rgba(0, 0, 0, 0.3) !important;
                }
            }
        }
        .main_logo {
            padding: 25px 0px 25px 0px;
            background: #f3f5f7;
            width: 100% !important;
            height: 120px;
            @media (min-width: 768px) {
                height: 150px;
            }
            img.kwiat {
                // max-width: 35%;
                // @media (max-width: 768px) {
                //     max-width: 15%;
                // }
                // @media (min-width: 1080px) {
                //     max-width: 15%;
                // }
                max-width: 100%;
                max-height: 100%;
            }
        }
        .search_box {
            width: 100% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 10px;
            margin: 30px 0px 25px 0px;
            font-size: 15px;

            label {
                font-size: 14px;
                color: #838383;
                @media (min-width: 768px) {
                    font-size: 18px;
                }
            }
            input {
                width: 60%;
                border: #acacac solid 1.5px;
                @media (min-width: 768px) {
                    width: 40%;
                    font-size: 18px;
                }
            }
            input:focus {
                // border: #acacac solid 1.5px;
                outline: none;
            }
            button {
                width: 18%;
                font-size: 14px;
                background: #31406f;
                color: #fff !important;
                text-shadow: none !important;
                // border-color: #58585a !important;
                border: none !important;
                padding: 4px;
                @media (min-width: 768px) {
                    width: 12%;
                    font-size: 16px;
                    padding: 8px;
                }
            }
        }
    }

    .search_components_results {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #f3f5f7;
        column-gap: 4%;
        position: sticky;
        top: 0;
        height: 75px;
        width: 100%;
        z-index: 10;
        @media (min-width: 768px) {
            height: 110px;
        }
        .main_logo {
            padding: 0px 0px 0px 10px;
            background: #f3f5f7;
            /* width: 100%!important; */
            height: 100%;
            width: 45%;
            display: grid;
            @media (min-width: 768px) {
                padding: 10px 0px 10px 30px;
            }
            img.kwiat {
                max-width: 25%;
                // max-height: 100%;
                // @media (min-width: 470px) and (max-width: 600px) {
                //     max-width: 45%;
                // }
                // @media (min-width: 1199px) {
                //     width: 22%;
                // }
                // @media (min-width: 1024px) {
                //     width: 25%;
                // }
                @media (min-width: 991px) {
                    width: 32%;
                }
                @media (min-width: 768px) and(max-width:991px) {
                    max-width: 38%;
                }
                @media (max-width: 768px) {
                    max-width: 35%;
                }
                @media (max-width: 525px) {
                    max-width: 50% !important;
                }
                @media (max-width: 425px) {
                    max-width: 60% !important;
                }
                margin: auto;
            }
            img.fred {
                max-width: 100%;
                margin: auto;
                // @media (min-width: 1080px) {
                //     max-width: 100%;
                // }
            }
        }
        .search_box {
            width: 65% !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            /* grid-row-gap: 10px; */
            // column-gap: 5px;
            /* margin: 30px 0 25px; */
            font-size: 15px;
            padding: 0px 2px 0px 0px;

            label {
                font-size: 12px;
                color: #838383;
            }
            input {
                width: 62%;
                border: 1.5px solid #acacac;
                height: 25px;
                font-size: 14px;
                margin-right: 7px;
                @media (min-width: 768px) {
                    height: 35px;
                    font-size: 16px;
                }
            }
            input:focus {
                // border: #acacac solid 1.5px;
                outline: none;
            }
            button {
                width: 33%;
                height: 25px;
                font-size: 14px;
                background: #31406f;
                color: #fff !important;
                text-shadow: none !important;
                border: none !important;
                @media (min-width: 768px) {
                    height: 35px;
                    font-size: 16px;
                }
            }
        }
    }
    .result {
        position: absolute;
        top: 50vh;
        width: 100%;
    }

    .scan {
        margin-top: 10px;
        cursor: pointer;
    }

    .item_container {
        text-align: left;
        width: 100%;
        padding: 0px 0px 15px 0px;
        // position: absolute;
        // opacity: 0;
        .item {
            text-align: center;
            max-width: 100%;
            .logo {
                padding: 15px 0px;
                background: #f3f5f7;
                img.kwiat {
                    max-width: 25%;
                    @media (min-width: 768px) {
                        max-width: 15%;
                    }
                    @media (min-width: 1080px) {
                        max-width: 8%;
                    }
                }
                img.fred {
                    max-width: 60%;
                    @media (min-width: 1080px) {
                        max-width: 30%;
                    }
                }
            }
            .item_description {
                padding: 10px 25px;
                display: flex;
                flex-direction: column;
                @media (min-width: 768px) {
                    width: 40%;
                    margin: auto;
                }
                .collection_itemName {
                    display: flex;
                    flex-direction: column;
                    grid-row-gap: 10px;
                    row-gap: 10px;
                    padding: 0px;
                    .collection {
                        color: #838383;
                        text-decoration: underline;
                        font-size: 20px;
                    }
                    .itemName {
                        color: #31406f;
                        font-family: -webkit-body;
                        font-size: 28px;
                    }
                }

                .retail_price {
                    margin: 10px 0px 25px 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    grid-column-gap: 5px;
                    column-gap: 5px;
                    label {
                        font-size: 32px;
                        color: #31406f;
                        font-family: serif;
                        font-weight: bold;
                    }
                }

                .serial_no,
                .style_no {
                    label {
                        color: #1b387d;
                        font-size: 12px;
                        font-weight: bold;
                    }
                    label:nth-child(2) {
                        margin-left: 4px;
                        font-weight: normal;
                    }
                }
            }
            // font-size: 22px;

            .fields {
                margin: 25px 0px 0px 0px;
                .accordion-item {
                    border-color: white;
                    background-color: #f2f4f5;
                    .accordion-header,
                    .accordion-button {
                        @media (min-width: 768px) {
                            background-color: #f2f4f5;
                        }
                    }
                    .accordion-button {
                        background-color: #f2f4f5;
                        @media (min-width: 768px) {
                            width: 70%;
                            margin: auto;
                        }
                        font-weight: 600 !important;
                        color: #1b387d !important;
                        font-size: 14px;
                    }
                    .accordion-button:focus {
                        box-shadow: none;
                        // color: #1b387d !important;
                        // font-weight: 700;
                    }
                    // .accordion-button:not(.collapsed),
                    // .accordion-button:focus {
                    //     color: #1b387d !important;
                    //     // font-weight: 700;
                    //     // font-size: 1.5rem;
                    //     // background-color: #e7f1ff;
                    //     // box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
                    // }
                    .accordion-button:not(.collapsed):after {
                        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E") !important;
                        // font-size: 1.5rem;
                    }

                    .accordion-body {
                        display: flex;
                        flex-direction: column;
                        background: white;
                        .item_image {
                            width: 100%;
                            padding: 30px 0px 0px;
                            // img {
                            //     width: 80%;
                            // }
                            @media (min-width: 768px) {
                                width: 70%;
                                margin: auto;
                            }
                            .image-gallery-slides {
                                .image-gallery-image,
                                iframe,
                                video {
                                    max-height: 40vh;
                                    min-height: 40vh;
                                    width: 100%;
                                    @media (min-width: 768px) {
                                        max-height: 60vh;
                                    }
                                }
                                iframe,
                                video {
                                    @media (min-width: 768px) {
                                        min-height: 60vh;
                                    }
                                }
                            }
                            .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
                                padding: 10px;
                                // @media (min-width: 1080px) {
                                //     width: 70%;
                                //     margin: auto;
                                // }
                                .image-gallery-thumbnails {
                                    display: grid;
                                    .image-gallery-thumbnails-container {
                                        float: left;
                                        .image-gallery-thumbnail {
                                            width: 52px;
                                            height: 52px;
                                            margin: 0px 4px;
                                            @media (min-width: 768px) {
                                                width: 82px;
                                                height: 82px;
                                            }
                                            @media (min-width: 1440px) {
                                                width: 86px;
                                                height: 86px;
                                            }
                                            outline: none;
                                            border: 1px solid #acacac;
                                        }
                                        .image-gallery-thumbnail.active,
                                        .image-gallery-thumbnail:focus,
                                        .image-gallery-thumbnail:hover {
                                            outline: none;
                                            border: 1px solid #1c3279;
                                        }
                                    }
                                }
                            }
                        }

                        // row-gap: 8px;
                        .field_data {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            border-bottom: 0.5px solid #e1e1e1;
                            padding: 8px 4px;
                            font-size: 14px;
                            // font-weight: 500;
                            color: #7a7a7a;
                            @media (min-width: 768px) {
                                width: 70%;
                                margin: auto;
                            }

                            label {
                                width: 45%;
                                text-align: left;
                                @media (min-width: 768px) {
                                    width: 30%;
                                }
                            }
                            label:nth-child(2) {
                                width: 55%;
                                @media (min-width: 768px) {
                                    width: 70%;
                                }
                            }
                        }
                        .field_data:last-child {
                            border-bottom: none;
                        }
                        .report_img {
                            cursor: pointer;
                            margin-bottom: 10px;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}
.op_0 {
    opacity: 0 !important;
}
.op_1 {
    opacity: 1 !important;
}
.pos_relative {
    position: relative !important;
}

.h_100vh {
    height: 100vh !important;
}
.h_100 {
    height: 100% !important;
}
.Toastify__toast-container {
    z-index: 10021 !important;
}
.css-1mnns6r {
    position: relative;
}
.Toastify__toast {
    min-height: 54px !important;
}
.Toastify__toast--default {
    background: #cecece !important;
    color: #3c3c3c !important;
}
